<template>
    <div>
        <CRow>
           <!-- <CCol lg="10">
                <h1>Hello! Good {{this.determineIfMorningAfternoonOrEvening()}} {{$store.getters['getUserDetails'] ? $store.getters['getUserDetails'].first_name : ''}}.</h1>
            </CCol> -->
            <CCol lg="2">
                <label>Select Date Range :</label>
                <v-select 
                    label="Selected Period"
                    :options="period"
                    :clearable="false"
                    v-model="selected_period"
                    :on-change="handleChange()"
                />
            </CCol>
            <CCol lg="4">
                <div v-if="selected_period == 'Is Between'">
                    <vc-date-picker
                        v-model="range"
                        mode="date"
                        is-range
                        :masks="masks" 
                    >
                        <!-- :on-change="onChangeDateRange()"  -->
                    <template v-slot="{ inputValue, inputEvents, isDragging }">
                        <CRow>
                            <CCol>
                                <label>From :</label>
                                <CInput
                                    :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                    :value="inputValue.start"
                                    v-on="inputEvents.start"
                                    readonly
                                    style="background-color:#fff !important"
                                >
                                    <template #prepend-content>
                                        <CIcon name="cil-calendar"/>
                                    </template>
                                </CInput>
                            </CCol>
                            <CCol>
                                <label>To :</label>
                                <CInput
                                    :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                    :value="inputValue.end"
                                    v-on="inputEvents.end"
                                    readonly
                                    style="background-color:#fff !important"
                                >
                                    <template #prepend-content>
                                        <CIcon name="cil-calendar"/>
                                    </template>
                                </CInput>
                            </CCol>
                        </CRow>
                        </template>
                    </vc-date-picker>
                </div>
            </CCol>
        </CRow>
        <br>
        <CRow>
            <CCol lg="12">
               <SummaryPanel :selectedDateRange="range" :shouldRunFunctions='should_run_functions' :selectedPeriod = 'selected_period'/>
            </CCol>
        </CRow>
        <!-- <br>
        <CRow>
            <CCol lg="12">
               <DetailedPanel/>
            </CCol>
        </CRow> -->
    </div>
</template>
<style type="text/css">
    .form-control[readonly] {
        background-color: #fff !important;
        opacity: 1 !important;
    }
</style>
<script>

import CDateRange from '../components/CDateRange'
import SummaryPanel from './SummaryPanel.vue'
import DetailedPanel from './DetailedPanel.vue'
export default {
    name: 'Dashboard',
    components: {
        SummaryPanel,
        DetailedPanel,
        CDateRange,        
    },
    data(){
        return {
            period: ['All','Is Between','Today', 'This Week', 'This Month', 'This Quarter', 'This Year', 'Yesterday', 'Last Week', 'Last Month', 'Last Quarter', 'Last Year',],
            selected_period: 'All',
            should_run_functions: true,
            range: {
                start: new Date(),
                end: new Date(),
            },
            masks: {
                input: 'MMMM DD, YYYY',
            },
        }
    },
    created(){
        // this.generateArrayOfYears();
    },
    methods: {
        determineIfMorningAfternoonOrEvening(){
            let d = new Date();
            if(d.getHours() >= 0 && d.getHours() < 12){
                return 'morning'
            }else if(d.getHours() >= 12 && d.getHours() < 18){
                return 'afternoon'
            }else{
                return 'evening'
            }
        },
        handleChange() {
            if (this.selected_period !== 'Is Between') {
                this.should_run_functions = true;
                this.range.start = new Date();
                this.range.end = new Date();
            } else {
                this.should_run_functions = false;
            }
        },
    },
}
</script>