<template>
    <div>
        <CRow>
            <CCol lg="4">
                <center v-if="document_for_signing_loading">
                    <CButton disabled>
                      <CSpinner component="span" size="sm" variant="grow" aria-hidden="true"/>
                      Loading...
                    </CButton>
                </center>
                <a href="javascript:void(0);" style="text-decoration: none;" @click="redirectToDocumentRouting('signing-list',for_signing.reference_number)">
                    <CWidgetDropdown 
                        v-if="!document_for_signing_loading"
                        color="gradient-info" 
                        :header="document_for_signing" 
                        text="Document Routing For Signing">
                        <template #footer>
                            <CChartLineSimple
                                class="mt-3"
                                style="height:70px"
                                background-color="rgba(255,255,255,.2)"
                                point-hover-background-color="primary"
                                :data-points="document_for_signing_data_points"
                                :options="{ elements: { line: { borderWidth: 4 }}}"
                                label="Document/s"
                                :labels="labels.for_signing"
                            />
                        </template>
                    </CWidgetDropdown>
                </a>
            </CCol> 
            <CCol lg="4">
                <center v-if="document_signed_loading">
                    <CButton disabled>
                      <CSpinner component="span" size="sm" variant="grow" aria-hidden="true"/>
                      Loading...
                    </CButton>
                </center>
            <!-- <a href="javascript:void(0);" style="text-decoration: none;" @click="redirectToDocumentRouting('completed-list')"> -->
                <CWidgetDropdown 
                    v-if="!document_signed_loading"
                    color="gradient-success" 
                    :header="document_signed" 
                    text="Document Routing Signed">
                    <template #default v-if="signed.display">
                        <CDropdown
                            color="transparent p-0"
                            placement="bottom-end"
                            v-if="signed.display"
                        >
                            <template #toggler-content>
                            More details 
                            </template>
                            <CDropdownItem v-if="signed.on_process != 0" @click="redirectToDocumentRouting('processing-list',signed.reference_number)">{{ signed.on_process }} On Process</CDropdownItem>
                            <CDropdownItem v-if="signed.completed != 0" @click="redirectToDocumentRouting('completed-list',signed.reference_number)">{{ signed.completed }} Completed</CDropdownItem>
                        </CDropdown>
                    </template>
                    <template #footer>
                        <CChartLineSimple
                            class="mt-3"
                            style="height:70px"
                            background-color="rgba(255,255,255,.2)"
                            point-hover-background-color="primary"
                            :data-points="document_signed_data_points"
                            :options="{ elements: { line: { borderWidth: 4 }}}"
                            label="Document/s"
                            :labels="labels.signed"
                        />
                    </template>
                </CWidgetDropdown>
            <!-- </a> -->
            </CCol>
            <CCol lg="4">
                <center v-if="transactions_loading">
                    <CButton disabled>
                      <CSpinner component="span" size="sm" variant="grow" aria-hidden="true"/>
                      Loading...
                    </CButton>
                </center>
            <!-- <a href="javascript:void(0);" style="text-decoration: none;" @click="redirectToDocumentRouting('completed-list')"> -->
                <CWidgetDropdown 
                    v-if="!transactions_loading"
                    color="gradient-dark" 
                    :header="transaction" 
                    text="Transactions">
                    <template #default>
                        <CDropdown
                            color="transparent p-0"
                            placement="bottom-end"
                            v-if="transactions.display"
                        >
                            <template #toggler-content>
                            More details 
                            </template>

                            <a-popover title="Document Created" placement="rightTop" v-if="routing_status.display">
                                <template slot="content">
                                    <ul class="list-unstyled">
                                        <li @click="redirectToDocumentRouting('draft-list',transactions.reference_number)" v-if="routing_status.draft != 0">
                                            <a href="javascript:void(0)">
                                                <strong>{{ routing_status.draft }}</strong> 
                                                Draft 
                                            </a>
                                        </li>
                                        <li @click="redirectToDocumentRouting('processing-list',transactions.reference_number)" v-if="routing_status.on_process != 0">
                                            <a href="javascript:void(0)">
                                                <strong>{{ routing_status.on_process }}</strong>
                                                For Signing/On Process 
                                            </a>
                                        </li>
                                        <li @click="redirectToDocumentRouting('completed-list',transactions.reference_number)" v-if="routing_status.completed != 0">
                                            <a href="javascript:void(0)">
                                                <strong>{{ routing_status.completed }}</strong>
                                                Completed 
                                            </a> 
                                        </li>
                                        <li @click="redirectToDocumentRouting('cancelled-list',transactions.reference_number)" v-if="routing_status.cancelled != 0">
                                            <a href="javascript:void(0)">
                                                <strong>{{ routing_status.cancelled }}</strong> 
                                                Cancelled 
                                            </a>
                                        </li>
                                    </ul> 
                                </template>
                                <CDropdownItem> As Creator </CDropdownItem>
                            </a-popover>

                            <a-popover title="Signatory Details" placement="rightTop" v-if="signing_status.display">
                                <template slot="content">
                                    <strong v-if="signing_status.draft.display">Draft</strong>
                                        <ul class="list-unstyled">
                                            <li v-if="signing_status.draft.for_signing != 0">
                                                    <strong>{{ signing_status.draft.for_signing }}</strong> 
                                                    For Signing
                                            </li>
                                            <li v-if="signing_status.draft.signed != 0">
                                                    <strong>{{ signing_status.draft.signed }}</strong> 
                                                    Signed
                                            </li>
                                            <li v-if="signing_status.draft.pending != 0">
                                                    <strong>{{ signing_status.draft.pending }}</strong> 
                                                    Pending
                                            </li>     
                                            <li v-if="signing_status.draft.removed != 0">
                                                    <strong>{{ signing_status.draft.removed }}</strong> 
                                                    Removed
                                            </li>
                                        </ul>
                                    <strong v-if="signing_status.signing.display">Signing</strong>
                                        <ul class="list-unstyled">
                                            <li v-if="signing_status.signing.for_signing != 0" @click="redirectToDocumentRouting('signing-list',transactions.reference_number)">
                                                <a href="javascript:void(0)">
                                                    <strong>{{ signing_status.signing.for_signing }}</strong> 
                                                    For Signing 
                                                </a>
                                            </li>
                                            <li v-if="signing_status.signing.signed != 0" @click="redirectToDocumentRouting('processing-list',transactions.reference_number)">
                                                <a href="javascript:void(0)">
                                                    <strong>{{ signing_status.signing.signed }}</strong> 
                                                    Signed
                                                </a> 
                                            </li>
                                            <li v-if="signing_status.signing.pending != 0" @click="redirectToDocumentRouting('processing-list',transactions.reference_number)">
                                                <a href="javascript:void(0)">
                                                    <strong>{{ signing_status.signing.pending }}</strong> 
                                                    Pending
                                                </a>
                                            </li>     
                                            <li v-if="signing_status.signing.removed != 0" @click="redirectToDocumentRouting('processing-list',transactions.reference_number)">
                                                <a href="javascript:void(0)">
                                                    <strong>{{ signing_status.signing.removed }}</strong> 
                                                    Removed
                                                </a>
                                            </li>
                                        </ul>
                                    <strong v-if="signing_status.completed.display">Completed</strong>
                                        <ul class="list-unstyled">
                                            <li v-if="signing_status.completed.for_signing != 0" @click="redirectToDocumentRouting('completed-list',transactions.reference_number)">
                                                <a href="javascript:void(0)">
                                                    <strong>{{ signing_status.completed.for_signing }}</strong> 
                                                    For Signing 
                                                </a>
                                            </li>
                                            <li v-if="signing_status.completed.signed != 0" @click="redirectToDocumentRouting('completed-list',transactions.reference_number)">
                                                <a href="javascript:void(0)">
                                                    <strong>{{ signing_status.completed.signed }}</strong> 
                                                    Signed
                                                </a> 
                                            </li>
                                            <li v-if="signing_status.completed.pending != 0" @click="redirectToDocumentRouting('completed-list',transactions.reference_number)">
                                                <a href="javascript:void(0)">
                                                    <strong>{{ signing_status.completed.pending }}</strong> 
                                                    Pending
                                                </a>
                                            </li>     
                                            <li v-if="signing_status.completed.removed != 0" @click="redirectToDocumentRouting('completed-list',transactions.reference_number)">
                                                <a href="javascript:void(0)">
                                                    <strong>{{ signing_status.completed.removed }}</strong> 
                                                    Removed
                                                </a>
                                            </li>
                                        </ul>
                                    <strong v-if="signing_status.cancelled.display">Cancelled</strong>
                                        <ul class="list-unstyled">
                                            <li v-if="signing_status.cancelled.for_signing != 0" @click="redirectToDocumentRouting('cancelled-list',transactions.reference_number)">
                                                <a href="javascript:void(0)">
                                                    <strong>{{ signing_status.cancelled.for_signing }}</strong> 
                                                    For Signing 
                                                </a>
                                            </li>
                                            <li v-if="signing_status.cancelled.signed != 0" @click="redirectToDocumentRouting('cancelled-list',transactions.reference_number)">
                                                <a href="javascript:void(0)">
                                                    <strong>{{ signing_status.cancelled.signed }}</strong> 
                                                    Signed
                                                </a> 
                                            </li>
                                            <li v-if="signing_status.cancelled.pending != 0" @click="redirectToDocumentRouting('cancelled-list',transactions.reference_number)">
                                                <a href="javascript:void(0)">
                                                    <strong>{{ signing_status.cancelled.pending }}</strong> 
                                                    Pending
                                                </a>
                                            </li>     
                                            <li v-if="signing_status.cancelled.removed != 0" @click="redirectToDocumentRouting('cancelled-list',transactions.reference_number)">
                                                <a href="javascript:void(0)">
                                                    <strong>{{ signing_status.cancelled.removed }}</strong> 
                                                    Removed
                                                </a>
                                            </li>
                                        </ul>
                                </template>
                                <CDropdownItem> As Approver </CDropdownItem>
                            </a-popover>

                        </CDropdown>
                    </template>
                    <template #footer>
                        <CChartLineSimple
                            class="mt-3"
                            style="height:70px"
                            background-color="rgba(255,255,255,.2)"
                            :data-points="transactions_data_points"
                            :options="{ elements: { line: { borderWidth: 4 }}}"
                            point-hover-background-color="warning"
                            label="Document/s"
                            :labels="labels.transactions"
                        />
                    </template>
                </CWidgetDropdown>
            <!-- </a> -->
            </CCol>
            <!-- <CCol lg="4">
                <CWidgetDropdown 
                    color="gradient-danger" 
                    header="10" 
                    text="Document Cancelled">
                    <template #default>
                        <CDropdown
                            color="transparent p-0"
                            placement="bottom-end"
                        >
                            <template #toggler-content>
                            <CIcon name="cil-settings"/>
                            </template>
                            <CDropdownItem>Action</CDropdownItem>
                            <CDropdownItem>Another action</CDropdownItem>
                            <CDropdownItem>Something else here...</CDropdownItem>
                            <CDropdownItem disabled>Disabled action</CDropdownItem>
                        </CDropdown>
                    </template>
                    <template #footer>
                        <CChartLineSimple
                            class="mt-3"
                            style="height:70px"
                            background-color="rgba(255,255,255,.2)"
                            point-hover-background-color="primary"
                            :data-points="document_signed_data_points"
                            :options="{ elements: { line: { borderWidth: 4 }}}"
                            label="Document/s"
                            :labels="labels"
                        />
                    </template>
                </CWidgetDropdown>
            </CCol> -->
            
            <CElementCover :opacity="0.8" v-if="false"/>
        </CRow>
    </div>
</template>
<script>
import { CChartLineSimple, CChartLineSimple2, CChartBarSimple } from '../components/charts'
export default {
    name:'SummaryPanel',
    props: ['selectedPeriod','shouldRunFunctions','selectedDateRange'],
    components: { 
        CChartLineSimple,
        CChartBarSimple,
        CChartLineSimple2,
    },
    data() {
        return {
            labels: {
                for_signing: null,
                signed: null,
                transactions: null
            },
            document_for_signing_loading: true,
            document_for_signing_data_points: [],
            document_for_signing: "",
            document_signed_loading: true,
            document_signed: "",
            document_signed_data_points: [],
            transactions_loading: true,
            transaction: "",
            transactions_data_points: [],
            date_from: null,
            date_to: null,
            response: {},
            for_signing: {
                reference_number: []
            },
            signed: {
                reference_number: [],
                on_process: 0,
                completed: 0,
                display: false
            },
            transactions: {
                reference_number: [],
                created_routing : 0,
                on_process: 0,
                completed: 0,
                display: false,
            },
            routing_status: {
                draft: 0,
                on_process: 0,
                completed: 0,
                cancelled: 0,
                display: false,
            },
            signing_status: {
                draft: {
                    for_signing: 0,
                    signed: 0,
                    pending: 0,
                    removed: 0,
                    display: false
                },
                signing: {
                    for_signing: 0,
                    signed: 0,
                    pending: 0,
                    removed: 0,
                    display: false
                },
                completed: {
                    for_signing: 0,
                    signed: 0,
                    pending: 0,
                    removed: 0,
                    display: false
                },
                cancelled: {
                    for_signing: 0,
                    signed: 0,
                    pending: 0,
                    removed: 0,
                    display: false
                },
                display: false,
            }
        };
    },
    created() {
        this.getUserNumberOfDocumentForSigning();
        this.getUserNumberOfDocumentSigned();
        this.getUserNumberOfTransactions();
    },
    methods: {
        compareDates: function (date) {
          let current_date = new Date();
          let date_to = new Date(date);
          if (date_to > current_date) {
            let year = current_date.getFullYear();
            let month = String(current_date.getMonth() + 1).padStart(2, '0');
            let day = String(current_date.getDate()).padStart(2, '0');
            let formattedDate = `${year}-${month}-${day}`
            return formattedDate
          } else {
            return date_to == 0 ? null : date
          }
        },
        getTimeZone: function() {
            var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
            return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
        },
        redirectToDocumentRouting: function(status,reference_number) {
            this.$router.push({ path: `/${this.$store.getters['getOrganizationName']}/general-access/document-routing`, 
                                query: {
                                    tab: status,
                                    // date_from: this.response.date_from,
                                    // date_to: this.compareDates(this.response.date_to),
                                    dashboard_reference_number: reference_number
                                },
                              });
        },
        getUserNumberOfDocumentForSigning: function () {
        
            let data = { period: this.selectedPeriod,
                         timezone: this.getTimeZone(),
                         date_from: this.date_from,
                         date_to: this.date_to
                        }
            this.for_signing.reference_number = []
            axios.post('/dashboard/get-number-of-document-for-signing',data, {validateStatus: () => true}).then(response => {
                if(response.status == 200) {
                    
                    this.for_signing.reference_number = response.data.data.referenceNumber
                    this.document_for_signing = response.data.data.dataCount.toString()
                    let period = response.data.data.periodCount.sort((a, b) => new Date(parseInt(a.period)) - new Date(parseInt(b.period)))
                    let data_points = []
                    for (var i = 0; i < period.length; i++) {
                        data_points.push(period[i].count)        
                    };  
                    this.labels.for_signing = response.data.data.labels
                    this.document_for_signing_data_points = data_points
                    this.document_for_signing_loading = false
                    this.response.date_to = this.selectedPeriod !== 'All' ? response.data.data.dateTo.toString() : null
                    this.response.date_from = this.selectedPeriod !== 'All' ? response.data.data.dateFrom.toString() : null
                }
                return
            })
        },
        getUserNumberOfDocumentSigned: function () {
            let data = { period: this.selectedPeriod,
                         timezone: this.getTimeZone(),
                         date_from: this.date_from,
                         date_to: this.date_to
                        }
            this.signed.on_process = 0
            this.signed.completed = 0
            this.signed.display = false
            this.signed.reference_number = []
            axios.post('/dashboard/get-number-of-document-signed',data, {validateStatus: () => true}).then(response => {
                if(response.status == 200) {
                    
                    this.signed.reference_number = response.data.data.referenceNumber
                    this.document_signed = response.data.data.dataCount.toString()
                    let period = response.data.data.periodCount.sort((a, b) => new Date(parseInt(a.period)) - new Date(parseInt(b.period)))
                    let data_points = []
                    for (var i = 0; i < period.length; i++) {
                        data_points.push(period[i].count)        
                    };  
                    if(response.data.data.data.length > 0){
                        let signed_data = response.data.data.data
                        for (var i = 0; i < signed_data.length; i++) {
                            switch(signed_data[i].status){
                                case 2:
                                    this.signed.on_process = signed_data[i].total
                                    this.signed.display = true
                                break;

                                case 3:
                                    this.signed.completed = signed_data[i].total
                                    this.signed.display = true
                                break;

                                default:
                                    this.signed.on_process = 0
                                    this.signed.completed = 0
                                    this.signed.display = false
                                break;
                            }
                        }
                    } else {
                        this.signed.on_process = 0
                        this.signed.completed = 0
                        this.signed.display = false
                    }

                    this.labels.signed = response.data.data.labels
                    this.document_signed_data_points = data_points
                    this.document_signed_loading = false
                    this.response.date_to = this.selectedPeriod !== 'All' ? response.data.data.dateTo.toString() : null
                    this.response.date_from = this.selectedPeriod !== 'All' ? response.data.data.dateFrom.toString() : null
                }
                return
            })
        },
        getUserNumberOfTransactions: function () {
            let data = { period: this.selectedPeriod,
                         timezone: this.getTimeZone(),
                         date_from: this.date_from,
                         date_to: this.date_to
                        }
            this.signing_status.draft.for_signing = 0
            this.signing_status.draft.signed = 0
            this.signing_status.draft.pending = 0
            this.signing_status.draft.removed = 0
            this.signing_status.draft.display = false
            this.signing_status.signing.for_signing = 0
            this.signing_status.signing.signed = 0
            this.signing_status.signing.pending = 0
            this.signing_status.signing.removed = 0
            this.signing_status.signing.display = false
            this.signing_status.completed.for_signing = 0
            this.signing_status.completed.signed = 0
            this.signing_status.completed.pending = 0
            this.signing_status.completed.removed = 0
            this.signing_status.completed.display = false
            this.signing_status.cancelled.for_signing = 0
            this.signing_status.cancelled.signed = 0
            this.signing_status.cancelled.pending = 0
            this.signing_status.cancelled.removed = 0
            this.signing_status.cancelled.display = false
            this.signing_status.display = false
            this.routing_status.draft = 0
            this.routing_status.on_process = 0
            this.routing_status.completed = 0
            this.routing_status.cancelled = 0
            this.routing_status.display = false
            this.transactions.reference_number = []
            axios.post('/dashboard/get-number-of-transactions',data, {validateStatus: () => true}).then(response => {
                if(response.status == 200) {
                    
                    this.transactions.reference_number = response.data.data.referenceNumber
                    let period = response.data.data.periodCount.sort((a, b) => new Date(parseInt(a.period)) - new Date(parseInt(b.period)))
                    let data_points = []
                    for (var i = 0; i < period.length; i++) {
                        data_points.push(period[i].count)   
                    };  
                   
                    if(response.data.data.data.length > 0) {
                        this.transactions.display = true
                        let transactions_data = response.data.data.data
                        for (var i = 0; i < transactions_data.length; i++) {
                            if(transactions_data[i].signing_status == 'Creator') {

                                switch(transactions_data[i].routing_status){
                                    case 'Draft':
                                        this.routing_status.draft = transactions_data[i].total
                                        this.routing_status.display = true
                                    break;

                                    case 'Signing':
                                        this.routing_status.on_process = transactions_data[i].total
                                        this.routing_status.display = true
                                    break;

                                    case 'Completed':
                                        this.routing_status.completed = transactions_data[i].total
                                        this.routing_status.display = true
                                    break;

                                    case 'Cancelled':
                                        this.routing_status.cancelled = transactions_data[i].total
                                        this.routing_status.display = true
                                    break;

                                    default:
                                        this.routing_status.draft = 0
                                        this.routing_status.on_process = 0
                                        this.routing_status.completed = 0
                                        this.routing_status.cancelled = 0
                                        this.routing_status.display = false
                                    break;
                                }

                            } else {

                                switch(transactions_data[i].routing_status){
                                    case 'Draft':
                                        switch(transactions_data[i].signing_status) {
                                            case 'For Signing':
                                                this.signing_status.draft.for_signing = transactions_data[i].total
                                                this.signing_status.draft.display = true
                                                this.signing_status.display = true
                                            break;
                                            case 'Signed':
                                                this.signing_status.draft.signed = transactions_data[i].total
                                                this.signing_status.draft.display = true
                                                this.signing_status.display = true
                                            break;
                                            case 'Pending':
                                                this.signing_status.draft.pending = transactions_data[i].total
                                                this.signing_status.draft.display = true
                                                this.signing_status.display = true
                                            break;
                                            case 'Removed':
                                                this.signing_status.draft.removed = transactions_data[i].total
                                                this.signing_status.draft.display = true
                                                this.signing_status.display = true
                                            break;
                                            default:
                                                this.signing_status.draft.for_signing = 0
                                                this.signing_status.draft.signed = 0
                                                this.signing_status.draft.pending = 0
                                                this.signing_status.draft.removed = 0
                                                this.signing_status.draft.display = false
                                                this.signing_status.display = false
                                            break;
                                        }                                    
                                    break;

                                    case 'Signing':
                                        switch(transactions_data[i].signing_status) {
                                            case 'For Signing':
                                                this.signing_status.signing.for_signing = transactions_data[i].total
                                                this.signing_status.signing.display = true
                                                this.signing_status.display = true
                                            break;
                                            case 'Signed':
                                                this.signing_status.signing.signed = transactions_data[i].total
                                                this.signing_status.signing.display = true
                                                this.signing_status.display = true
                                            break;
                                            case 'Pending':
                                                this.signing_status.signing.pending = transactions_data[i].total
                                                this.signing_status.signing.display = true
                                                this.signing_status.display = true
                                            break;
                                            case 'Removed':
                                                this.signing_status.signing.removed = transactions_data[i].total
                                                this.signing_status.signing.display = true
                                                this.signing_status.display = true
                                            break;
                                            default:
                                                this.signing_status.signing.for_signing = 0
                                                this.signing_status.signing.signed = 0
                                                this.signing_status.signing.pending = 0
                                                this.signing_status.signing.removed = 0
                                                this.signing_status.signing.display = false
                                                this.signing_status.display = false
                                            break;
                                        }   
                                    break;

                                    case 'Completed':
                                        switch(transactions_data[i].signing_status) {
                                            case 'For Signing':
                                                this.signing_status.completed.for_signing = transactions_data[i].total
                                                this.signing_status.completed.display = true
                                                this.signing_status.display = true
                                            break;
                                            case 'Signed':
                                                this.signing_status.completed.signed = transactions_data[i].total
                                                this.signing_status.completed.display = true
                                                this.signing_status.display = true
                                            break;
                                            case 'Pending':
                                                this.signing_status.completed.pending = transactions_data[i].total
                                                this.signing_status.completed.display = true
                                                this.signing_status.display = true
                                            break;
                                            case 'Removed':
                                                this.signing_status.completed.removed = transactions_data[i].total
                                                this.signing_status.completed.display = true
                                                this.signing_status.display = true
                                            break;
                                            default:
                                                this.signing_status.completed.for_signing = 0
                                                this.signing_status.completed.signed = 0
                                                this.signing_status.completed.pending = 0
                                                this.signing_status.completed.removed = 0
                                                this.signing_status.completed.display = false
                                                this.signing_status.display = false
                                            break;
                                        }   
                                    break;

                                    case 'Cancelled':
                                        switch(transactions_data[i].signing_status) {
                                            case 'For Signing':
                                                this.signing_status.cancelled.for_signing = transactions_data[i].total
                                                this.signing_status.cancelled.display = true
                                                this.signing_status.display = true
                                            break;
                                            case 'signed':
                                                this.signing_status.cancelled.signed = transactions_data[i].total
                                                this.signing_status.cancelled.display = true
                                                this.signing_status.display = true
                                            break;
                                            case 'Pending':
                                                this.signing_status.cancelled.pending = transactions_data[i].total
                                                this.signing_status.cancelled.display = true
                                                this.signing_status.display = true
                                            break;
                                            case 'Removed':
                                                this.signing_status.cancelled.removed = transactions_data[i].total
                                                this.signing_status.cancelled.display = true
                                                this.signing_status.display = true
                                            break;
                                            default:
                                                this.signing_status.cancelled.for_signing = 0
                                                this.signing_status.cancelled.signed = 0
                                                this.signing_status.cancelled.pending = 0
                                                this.signing_status.cancelled.removed = 0
                                                this.signing_status.cancelled.display = true
                                                this.signing_status.display = false
                                            break;
                                        }   
                                    break;

                                    default:
                                        this.signing_status.draft.for_signing = 0
                                        this.signing_status.draft.signed = 0
                                        this.signing_status.draft.pending = 0
                                        this.signing_status.draft.removed = 0
                                        this.signing_status.draft.display = false
                                        this.signing_status.signing.for_signing = 0
                                        this.signing_status.signing.signed = 0
                                        this.signing_status.signing.pending = 0
                                        this.signing_status.signing.removed = 0
                                        this.signing_status.signing.display = false
                                        this.signing_status.completed.for_signing = 0
                                        this.signing_status.completed.signed = 0
                                        this.signing_status.completed.pending = 0
                                        this.signing_status.completed.removed = 0
                                        this.signing_status.completed.display = false
                                        this.signing_status.cancelled.for_signing = 0
                                        this.signing_status.cancelled.signed = 0
                                        this.signing_status.cancelled.pending = 0
                                        this.signing_status.cancelled.removed = 0
                                        this.signing_status.cancelled.display = false
                                        this.signing_status.display = false
                                    break;
                                }

                            }
                        } 
                    } else {
                        this.signing_status.draft.for_signing = 0
                        this.signing_status.draft.signed = 0
                        this.signing_status.draft.pending = 0
                        this.signing_status.draft.removed = 0
                        this.signing_status.draft.display = false
                        this.signing_status.signing.for_signing = 0
                        this.signing_status.signing.signed = 0
                        this.signing_status.signing.pending = 0
                        this.signing_status.signing.removed = 0
                        this.signing_status.signing.display = false
                        this.signing_status.completed.for_signing = 0
                        this.signing_status.completed.signed = 0
                        this.signing_status.completed.pending = 0
                        this.signing_status.completed.removed = 0
                        this.signing_status.completed.display = false
                        this.signing_status.cancelled.for_signing = 0
                        this.signing_status.cancelled.signed = 0
                        this.signing_status.cancelled.pending = 0
                        this.signing_status.cancelled.removed = 0
                        this.signing_status.cancelled.display = false
                        this.signing_status.display = false
                        this.routing_status.draft = 0
                        this.routing_status.on_process = 0
                        this.routing_status.completed = 0
                        this.routing_status.cancelled = 0
                        this.routing_status.display = false
                        this.transactions.display = false
                    }
                    
                    this.transaction = response.data.data.dataCount.toString()
                    this.labels.transactions = response.data.data.labels
                    this.transactions_data_points = data_points
                    this.transactions_loading = false
                    this.response.date_to = this.selectedPeriod !== 'All' ? response.data.data.dateTo.toString() : null
                    this.response.date_from = this.selectedPeriod !== 'All' ? response.data.data.dateFrom.toString() : null
                }
                return
            })
        },

        
    },
    watch: {
        selectedPeriod: function (value) { 
            if(this.shouldRunFunctions) {
                this.document_for_signing_loading = true
                this.document_signed_loading = true
                this.transactions_loading = true
                this.getUserNumberOfDocumentForSigning();
                this.getUserNumberOfDocumentSigned();
                this.getUserNumberOfTransactions();
            }

            
        },
        selectedDateRange: function (value) {
            if(this.selectedDateRange.start && this.selectedDateRange.end) {
                this.document_for_signing_loading = true
                this.document_signed_loading = true
                this.transactions_loading = true
                this.date_from = this.selectedDateRange.start.getFullYear() + "-" + ("0"+(this.selectedDateRange.start.getMonth()+1)).slice(-2) +"-"+("0" + this.selectedDateRange.start.getDate()).slice(-2)
                this.date_to = this.selectedDateRange.end.getFullYear() + "-" + ("0"+(this.selectedDateRange.end.getMonth()+1)).slice(-2) +"-"+("0" + this.selectedDateRange.end.getDate()).slice(-2)
                this.getUserNumberOfDocumentForSigning();
                this.getUserNumberOfDocumentSigned();
                this.getUserNumberOfTransactions();
            }
        }
    }
}
</script>